@tailwind base;
@tailwind components;
@tailwind utilities;

body::-webkit-scrollbar { 
	width: 0.25rem;
}

body::-webkit-scrollbar-track { 
	background: #1e1e24;
}

body::-webkit-scrollbar-thumb { 
	background: #6649b8;
}

@layer components {
  .sidebar-icon {
    @apply relative flex items-center justify-center 
    h-12 w-12 mt-2 mb-2 mx-auto  
  bg-gray-400 hover:bg-green-600 dark:bg-gray-800 
  text-green-500 hover:text-white
    hover:rounded-xl rounded-3xl
    transition-all duration-300 ease-linear
    cursor-pointer shadow-lg ;
  }
}

@layer base {
  :root {
    --c-uno: 60, 42, 77;
    --c-dos: 80, 58, 101;
    --c-tre: 87, 79, 125;
    --c-cua: 149, 173, 190;
    --c-cin: 200, 200, 200;
    --c-sei: 224, 240, 234;
    --c-sie: 240, 240, 240;
    --c-och: 255, 255, 255;
  }
  .theme-light {
    --c-uno: 39, 38, 67;
    --c-dos: 19, 78, 111;
    --c-tre: 44, 105, 141;
    --c-cua: 255, 97, 80;
    --c-cin: 26, 192, 198;
    --c-sei: 186, 232, 232;
    --c-sie: 227, 246, 245;
    --c-och: 255, 255, 255;
  }
  .theme-dark {
    --c-uno: 54, 47, 94;
    --c-dos: 12, 68, 116;
    --c-tre: 74, 106, 147;
    --c-cua: 105, 123, 211;
    --c-cin: 125, 166, 230;
    --c-sei: 185, 145, 175;
    --c-sie: 240, 240, 240;
    --c-och: 255, 255, 255;
  }
  .theme-jail {
    --c-uno: 80, 80, 80;
    --c-dos: 100, 100, 100;
    --c-tre: 120, 120, 120;
    --c-cua: 150, 150, 150;
    --c-cin: 200, 200, 200;
    --c-sei: 220, 220, 220;
    --c-sie: 240, 240, 240;
    --c-och: 255, 255, 255;
  }
  .theme-nature {
    --c-uno: 2, 47, 73;
    --c-dos: 5, 71, 108;
    --c-tre: 55, 134, 135;
    --c-cua: 126, 194, 153;
    --c-cin: 204, 240, 154;
    --c-sei: 255, 195, 1;
    --c-sie: 247, 163, 37;
    --c-och: 255, 255, 255;
  }
  .theme-semineon {
    --c-uno: 30, 0, 47;
    --c-dos: 40, 38, 104;
    --c-tre: 46, 42, 139;
    --c-cua: 46, 49, 145;
    --c-cin: 23, 189, 219;
    --c-sei: 255, 10, 145;
    --c-sie: 255, 195, 1;
    --c-och: 255, 255, 255;
  }
}